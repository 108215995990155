import { Skeleton } from "@material-ui/lab";
import useStyles from "./styles";
import clsx from 'clsx';

const EventSkeleton = (props) => {
  const { height = 1, width = 1 } = props;
  const classes = useStyles();

  return (
    <Skeleton variant="text"
      className={
        clsx({
          [classes.height1] : height === 1,
          [classes.height2] : height === 2,
          [classes.height3] : height === 3,
          [classes.width1]  : width === 1,
          [classes.width2]  : width === 2,
        })
      }
    />
  );
}

export default EventSkeleton
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  emptyTableContainer: {
    minHeight        : theme.spacing(62.5),
    display          : "flex",
    flexDirection    : "column",
    justifyContent   : "center",
    alignItems       : "center",
    '& .MuiSvgIcon-root': {
      fontSize   : theme.spacing(5.625),
      marginBottom : theme.spacing(.5),
    }
  }, 
  emptyTableTitle: {
    marginBottom : theme.spacing(2),
  }, 
  emptyTableDescriptionContainer: {
    width     : '25%',
    textAlign : 'center'
  }, 
  emptyTableDescription: {
    color: colors.LIGHT_GRAY_TEXT
  }, 
  mainTable: {
    borderCollapse: 'unset'
  },
}));
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Person as PersonIcon, RecentActors as RecentActorsIcon, Wifi as WifiIcon } from '@material-ui/icons/';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import Chips from '../../components/chips';
import SelectItems from '../../components/select-items';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { getAccessPointById } from '../../service/accessPointsApi';
import { getEventList } from '../../service/eventsApi';
import { API_REQUEST_ERROR_MESSAGE, CHIP_COLOR, DATE_CREATED, DATE_FORMAT, DATETIME_FORMAT, DAY, DESCENDING, EVENTS_MODULE, FILTER_EVENT_FORMAT, GET, LOCATIONS_MODULE, READ_MODE } from '../../utility/constants';
import { formatDate } from '../../utility/helper';
import { assignLocation, getLocation, unassignLocation } from '../../utility/location';
import { accessPointSchema } from '../../validation/schema';
import LoadingState from './access-point-skeleton';
import useStyles from './styles';

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { initialValues, handleSelectedLocation, selectedLocation, showToaster, disabled, handleSubmit, handleCancel, showLoading, handlePermissions } = props;

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={accessPointSchema}
      onSubmit={handleSubmit}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Box>
              <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={2} className={classes.form}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled={true}
                      className={classes.fullWidth}
                      id="accessPointName"
                      label={t('name')}
                      name="Name"
                      variant="outlined"
                      value={formik.values.name}
                    />
                    {
                      handlePermissions(LOCATIONS_MODULE, GET) &&
                        <SelectItems
                          id="accessPointLocations"
                          name="Locations"
                          onChange={handleSelectedLocation}
                          selectedItems={selectedLocation}
                          showToaster={showToaster}
                          single={true}
                          isValid={formik.touched.location && Boolean(formik.errors.location)}
                          helperText={t(formik.touched.location) && t(formik.errors.location)}
                          disabled={disabled}
                          handlePermissions={handlePermissions}
                        />
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled={true}
                      className={classes.fullWidth}
                      id="accessPointDescription"
                      label={t('description')}
                      name="description"
                      variant="outlined"
                      placeholder={t('noDescription')}
                      value={formik.values.description}
                      multiline
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid className={clsx(disabled ? 'hidden' : classes.action)}>
                  <Button
                    id="accessPointCancelButton"
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    id="accessPointSubmitButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {t('update')}
                  </Button>
                </Grid>
              </Paper>
            </Box>
          </Form>
        )
      }
    </Formik>
  );
}

const AccessPoint = (props) => {
  const { showToaster, match, showLoading, handlePermissions } = props;
  const path      = match.path;
  const { id }    = match.params;
  const classes   = useStyles();
  const history   = useHistory();
  const { t }     = useTranslation();
  const [isLoading, setIsLoading]                 = useState(false);
  const [isEventsLoading, setIsEventsLoading]     = useState(false);
  const [controllerName, setControllerName]       = useState('');
  const [name, setName]                           = useState('');
  const [description, setDescription]             = useState('');
  const [profiles, setProfiles]                   = useState([]);
  const [entries, setEntries]                     = useState([]);
  const [events, setEvents]                       = useState([]);
  const [withChanges, setWithChanges]             = useState(false);
  const [toRedirect, setToRedirect]               = useState('');
  const [showUnsavedModal, setShowUnsavedModal]   = useState(false);
  const [location, setLocation]                   = useState('');
  const [selectedLocation, setSelectedLocation]   = useState('');
  const [locationObject, setLocationObject]       = useState([]);

  const initialValues = {
    name        : `${controllerName}:${name}`,
    description : description,
    location    : selectedLocation
  }

  useEffect(() => {
    if (id) {
      getAccessPoint();
      if (name) {
        getEvents();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const getEvents = async () => {
    setIsEventsLoading(true);
    const params = {
      accessPoint : name,
      from        : moment(0).format(FILTER_EVENT_FORMAT),
      until       : moment().add(1, DAY).format(FILTER_EVENT_FORMAT),
      size        : 1000000,
      page        : 1,
      sort        : `${DATE_CREATED},${DESCENDING}`
    }

    try {
      const response = await getEventList(params);
      const eventData = response.events.map(event => {        
        return {
          id         : event.eventId.toString(),
          name       : event.subType,
          endContent : formatDate(event.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME),
        }
      });
      setEvents(eventData);  
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsEventsLoading(false);
    }
  }

  const getAccessPoint = async () => {
    setIsLoading(true);
    setWithChanges(false);

    if (name) {
      return;
    }

    try {
      const response                          = await getAccessPointById(id);
      const accessPoint                       = response;
      const { profiles, controller, readers } = accessPoint;
      
      getLocation(accessPoint, setLocation, setLocationObject);
      
      setControllerName(controller.name);
      setName(accessPoint.name);
      setDescription(accessPoint.description);

      if (profiles != null) {
        setProfiles(profiles.map(profile => {
          return {
            id          : profile.profileId,
            name        : profile.name,
            description : `${moment(profile.validFrom).format(DATE_FORMAT)} - ${moment(profile.validUntil).format(DATE_FORMAT)}`,
          }
        }));
      }

      if (readers != null) {
        setEntries(accessPoint.readers.map(reader => {
          return {
            name  : reader.name,
            description: t(READ_MODE[reader.readMode]) ?? ''
          }
        }));
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleUnsavedModalSubmit = () => {
    setWithChanges(false);
    setShowUnsavedModal(false);
    history.push(toRedirect);
  }

  const handleUnsavedModalCancel = () => {
    handleChanges();
    setShowUnsavedModal(false);
  }

  const handleCloseUnsavedModal = () => {
    setShowUnsavedModal(false);
  }

  const handleCancel = () => {
    history.push('/accessPoints');
  }

  const handleCancelLocation = () => {
    history.push('/accessPoints');
  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting } = formik;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/accessPoints');
      return;
    }

    try {
      const response = await getAccessPointById(id);
      
      const link = response._links.self.href;

      await assignLocationToAccessPoint(link);

      showToaster(t('success'), `${initialValues.name} ${t('hasBeenUpdated')}`, 'success');
      setWithChanges(false);
      setShowUnsavedModal(false);
      history.push('/accessPoints');
    } catch (error) {
      showToaster('Error', API_REQUEST_ERROR_MESSAGE, 'error');
      setWithChanges(true);
    } finally {
      showLoading(false);
      setSubmitting(false);
    }
  }

  const handleChanges = () => {
    if (location === selectedLocation) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const assignLocationToAccessPoint = async (link) => {
    if (location === selectedLocation) {
      return;
    }

    if (!Array.isArray(location)) {
      await unassignLocation(link);
    } 

    await assignLocation(link, selectedLocation);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(`accessPoints`)} subtitle={name}/>
      <UnsavedModal
        open={showUnsavedModal}
        onClose={handleCloseUnsavedModal}
        handleModalSubmit={handleUnsavedModalSubmit}
        handleModalCancel={handleUnsavedModalCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowUnsavedModal(true);
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname.startsWith('/access-points/update')
        }}
      />
        <Box className={classes.details}>
            <Grid container>
              <Grid item xs={6} className={classes.detailsBox}>
                  <Typography className={'bold'} color="secondary">Details</Typography>
              </Grid>
            </Grid>
            {
              isLoading ?
                <LoadingState disabled={path.includes('view')} />
              :
                <Content
                  handleSubmit={handleSubmit}
                  id={id}
                  initialValues={initialValues}
                  isLoading={isLoading}
                  path={path}
                  showToaster={showToaster}
                  handleSelectedLocation={handleSelectedLocation}
                  selectedLocation={locationObject}
                  handleCancel={handleCancel}
                  handleCancelLocation={handleCancelLocation}
                  disabled={path.includes('view')}
                  showLoading={showLoading}
                  handlePermissions={handlePermissions}
                />
            }
            <Grid container spacing={2} className={clsx(path.includes('view') ? classes.container : 'hidden')}>
              <Grid item md={3} lg={4}>
                <Chips
                  color={CHIP_COLOR.LIGHT}
                  data={profiles}
                  header={t('usedInProfiles')}
                  id="accessPointProfiles"
                  icon={<RecentActorsIcon/>}
                  isLoading={isLoading}
                  type={'Profiles'}
                />
              </Grid>
              <Grid item md={3} lg={4}>
                <Chips
                  id="accessPointReader"
                  color={CHIP_COLOR.LIGHT}
                  data={entries} 
                  header={t('reader')}
                  icon={<WifiIcon/>}
                  isLoading={isLoading}
                  type={'Readers'}
                />
              </Grid>
              <Grid item md={6} lg={4}>
                <Chips
                  id="accessPointEvents"
                  color={CHIP_COLOR.NEUTRAL}
                  data={events}
                  header={t('events')}
                  icon={<PersonIcon/>}
                  isLoading={isLoading || isEventsLoading}
                  leftHeader={t('event')}
                  rightHeader={t('dateCreated')}
                  type={EVENTS_MODULE}
                />
              </Grid>
            </Grid>
        </Box>
    </Container>
  );
}

export default AccessPoint;
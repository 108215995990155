import { Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip } from "@material-ui/core";
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, CloseOutlined as CloseOutlinedIcon, FilterList, SearchOutlined as SearchOutlinedIcon } from "@material-ui/icons";
import useStyles from "../enhanced-table/styles";
import { Trans, useTranslation } from "react-i18next";
import clsx from 'clsx';
import SkeletonRow from "./table-skeleton";
import EnhancedEmptyTable from "../enhanced-empty";
import { EnhancedTableHeadPropTypes, EnhancedTableProptypes, EnhancedTableRowPropTypes, EnhancedTableToolbarPropTypes } from "../utility/prop-types/enhanced-table";
import { Skeleton } from "@material-ui/lab";
import { FirstPageOutlined } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { ASCENDING, DESCENDING, EVENT_TABLE_COLUMNS, SORTED_ASCENDING, SORTED_DESCENDING } from "../../utility/constants";

export const createColumn = (id, label, isSortable, type, hidden = false, active = false, isSticky = false) => {

  return ({
    id,
    label,
    isSortable,
    type,   //Data type: "string", "boolean", "numeric", "date", "datetime", "time", "currency", "component"
    hidden,
    active,
    isSticky
  });
}

const SortHeader = (props) => {
  const classes = useStyles();
  const {active, columnId, getSortOrderDirection} = props;

  switch(getSortOrderDirection(columnId)){
    case ASCENDING:
      return (
        <ArrowUpwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      ) 
    case DESCENDING:
      return (
        <ArrowDownwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    default: 
      return (
        <></>
      )
  }
}

const EnhancedTableRow = (props) => {
  const { functions, values }                                                      = props;
  const { columns, tableHeads: keys, data, isSmallTable, rowHoverId, selectedRow } = values;
  const { handleRowClick, onHover }                                                = functions;   

  const classes = useStyles();

  return (
    <>
      {
        data.map((row, index) => {
          return (
            <TableRow
              data-testid={`data-row-${index}`}
              hover
              tabIndex={-1}
              key={`data-row-${index}`}
              onClick={() => handleRowClick(row.id)}
              className={clsx(rowHoverId === row.id ? classes.dataRowHover : classes.dataRow, selectedRow === row.id && classes.selectedRow)}
              onMouseEnter={() => onHover(row.id)}
              onMouseLeave={() => onHover(null)}
              id={row.id}
            >
              {
                keys.map((_, ctr) => {
                  const elementIdColumn = keys[ctr].charAt(0).toUpperCase() + keys[ctr].slice(1);
                  const currentColumn     = columns.find(column => column.id === keys[ctr])
                  
                  return (
                    <TableCell
                      key={`data-cell-${index}-${ctr}`}
                      data-testid={`data-cell-${index}-${ctr}`}
                      className={
                        clsx(
                          classes.baseCell,
                          {
                            [classes.stickyTableCell]   : currentColumn.isSticky,
                            [classes.smallerTableCell]  : !currentColumn.isSticky && isSmallTable,
                            [classes.timeColumn]        : elementIdColumn === EVENT_TABLE_COLUMNS.TIME,
                            [classes.selectedStickyCell]: selectedRow === row.id,
                            'hidden'                    : currentColumn.hidden
                          },
                        )} 
                    >
                      {
                        <span id={`${ctr}${elementIdColumn}`}>
                          {
                            row[keys[ctr]] ?
                              row[keys[ctr]]
                            :
                              ''
                          }
                        </span>
                      }
                    </TableCell>
                  )
                })
              }
            </TableRow>
          );
        })
      }
    </>
  );
}
EnhancedTableRow.propTypes = EnhancedTableRowPropTypes;

const EnhancedTableHead = (props) => {
  const { values, functions }          = props;
  const { onRequestSort }              = functions;
  const { order, orderBy, tableHeads, isSmallTable } = values;

  const classes = useStyles();

  const getSortOrder = () => {
    return (order === DESCENDING) ? SORTED_DESCENDING: SORTED_ASCENDING;
  }

  const getSortDirection = (id) => {
    return (orderBy === id) ? order : false;
  }

  const getSortOrderDirection = (id) => {
    return (orderBy === id) ? order : ASCENDING
  }

  return (
    <TableHead data-testid={`table-head`}>
      <TableRow>
        {
          tableHeads.map((column, index) => {
            return (
              <TableCell
                data-testid={`table-head-${index}`}
                key={'col-' + index}
                padding={'normal'}
                sortDirection={getSortDirection(column.id)}
                className={clsx(
                  isSmallTable ? classes.smallerTableHead : classes.headerStyle,
                  column.hidden && 'hidden')
                }
              >
                {
                  column.isSortable ?
                    <TableSortLabel
                      data-testid={`table-head-label-${index}`}
                      direction={getSortOrderDirection(column.id)}
                      onClick={(event) => onRequestSort(column.id)(event)}
                      hideSortIcon={true}
                    >
                      <Box className={classes.columnHeader}>
                        <Box>
                          <SortHeader
                            columnId={column.id}
                            active={column.active}
                            getSortOrderDirection={getSortOrderDirection}
                          />
                        </Box>
                        <Box className={classes.columnItem}>
                          <Trans i18nKey={column.label}/>
                        </Box>
                      </Box>
                      {
                        orderBy === column.id &&
                        <span className={classes.visuallyHidden}>
                          {getSortOrder()}
                        </span>
                      }
                    </TableSortLabel>
                  :
                    <Box data-testid={`table-head-label-${index}`} className={classes.columnItem}>
                      <Trans i18nKey={column.label}/>
                    </Box>
                }

              </TableCell>
            )
          })
        }
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = EnhancedTableHeadPropTypes;

const EnhancedTableToolbar = (props) => {
  const { values, functions } = props;
  const { page, rowsPerPage, isLoading, keyword, data, totalItems, isTotalItemsLoading } = values;
  const { onPageChange, handleSearch, handleClearSearch, handleFilter, onFirstPage } = functions;

  const classes = useStyles();
  const { t }   = useTranslation();

  const countElement = (count, to) => {
    return count !== -1 ? count : `more than ${to}`
  }

  const countTableItems = (from, to) => {
    from = to === 0 ? 0 : from;
    return `${from}–${to} ${t('of')}`;
  }

  const getDisabledProp = () => {
    return {
      disabled: isLoading || isTotalItemsLoading
    }
  }

  const getCustomProp = () => {
    return (isLoading || isTotalItemsLoading) && getDisabledProp()
  }

  return (
    <Toolbar data-testid={`table-toolbar`} className={classes.toolbar}>
      <Grid container className={classes.toolBarItemsContainer}>
        <Grid item xs={3} lg={4}>
          <TextField
            data-testid={`table-search-bar`}
            fullWidth
            className={classes.searchField}
            id={`SearchBar1`}
            onChange={event => handleSearch(event)}
            placeholder={t('search')}
            value={keyword}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id={`ClearSearchBar`}
                    edge="end"
                    onClick={handleClearSearch}
                    size="small"
                    className={
                      clsx({
                        [classes.visuallyHidden]: (keyword === null || keyword === "")
                      })
                    }
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={3} lg={3} style={{paddingTop: '-8px'}}>
          <Tooltip title={t('table-component.filter-list')}>
            <Button data-testid={`table-filter-button`} className={classes.filterButton} onClick={handleFilter}>
              <FilterList className={classes.icon} />
              {t('table-component.show-filters')}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={6} lg={5} style={{alignSelf:'center'}}>
          <Box container className={classes.paginationContainer}>
            {
              isTotalItemsLoading ?
                <Skeleton variant="rect" height={24} width={96}/>
              : 
                <></>
            }
            {
              data.length || isLoading ?
                <>
                  <IconButton
                    data-testid="first-page-button"
                    id="firstPageButton"
                    size="medium"
                    onClick={onFirstPage}
                    className={ clsx({
                      [classes.visuallyHidden]: (page < 2 || isTotalItemsLoading)
                    })}
                  >
                    <FirstPageOutlined />
                  </IconButton>
                  <TablePagination
                    data-testid={`table-pagination`}
                    component="div"
                    className={isTotalItemsLoading ? classes.paginationHide: ''}
                    labelRowsPerPage={t('rowPerPage')}
                    labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${countTableItems(from, to)} ${countElement(count, to)}`; }}
                    count={totalItems}
                    onPageChange={onPageChange}
                    page={page - 1}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    SelectProps={getDisabledProp()}
                    backIconButtonProps={getCustomProp()}
                    nextIconButtonProps={getCustomProp()}
                  />
                </>
              :
                <></>     
            }
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
}
EnhancedTableToolbar.propTypes = EnhancedTableToolbarPropTypes;

const EnhancedTableContent = (props) => {
  const { tableHeadProps } = props;
  return (
    <>
      <EnhancedTableHead {...tableHeadProps}/>
      <TableBody data-testid={`table-body`}>
        <EnhancedTableRow {...props} />
      </TableBody>
    </>
  );
}
EnhancedTableContent.propTypes = EnhancedTableRowPropTypes;

const EnhancedTable = (props) => {
  const { columns, page, rowsPerPage, data, isLoading, onChangePage, onClearSearch, onSearch, 
    onSort, keyword, totalItems, order, orderBy, onFilter, isTotalItemsLoading, module, handleRowClick, selectedRow } = props;

  const [tableHeads, setTableHeads]    = useState(columns);
  const [tableWidth, setTableWidth]    = useState(0);
  const [rowHoverId, setRowHoverId]    = useState(null);
  const classes                        = useStyles();
  const tableRef                       = useRef(null);

  const updateTableWidth = () => {
    if (tableRef.current) {
      const newWidth = tableRef.current.offsetWidth;
      setTableWidth(newWidth);
    }
  };

  useEffect(() => {
    let delayDebounce;
    let currentProp = tableRef.current;
    updateTableWidth();

    const resizeObserver = new ResizeObserver(() => {
      delayDebounce = setTimeout(() => {
        updateTableWidth();
      }, 250);
    });

    if (currentProp) {
      resizeObserver.observe(currentProp);
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce);
      if (currentProp) {
        resizeObserver.unobserve(currentProp);
      }
    };
  }, []);

  const handleSearch = (event) => {
    event.stopPropagation();
    onSearch(event.target.value);
  }

  const handleClearSearch = () => {
    onClearSearch();
  }

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
  };

  const handleFirstPage = () => {
    onChangePage(0);
  }

  const handleRequestSort = (event, newOrderBy) => {
    event.stopPropagation();
    const newOrder = order === ASCENDING ? DESCENDING : ASCENDING;
    onSort(newOrderBy, newOrder);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    const index = columns.findIndex(obj => obj.id === property)
    columns.map((column) => {
      column.active = false
      return null
    })
    columns[index].active = true

    setTableHeads(columns);
  };

  const onHover = (rowId) => {
    setRowHoverId(rowId);
  }

  const getRowData = (rows, columns) => {
    return rows.map(obj => {
      return Object.fromEntries(
        Object.entries(obj).filter(([key]) => columns.includes(key))
      );
    });
  }

  const stickyHeaders = tableHeads.filter(column => column.isSticky || column.hidden);
  const nonStickyHeaders = tableHeads.filter(column => !column.isSticky);
  
  const stickyColumnIds = stickyHeaders.map(column => column.id);
  const nonStickyColumnIds = nonStickyHeaders.map(column => column.id);

  const stickyData = getRowData(data, stickyColumnIds);
  const nonStickyData = getRowData(data, nonStickyColumnIds);

  const tableToolbarProps = {
    values: {
      rowsPerPage, 
      page, 
      isLoading,
      isTotalItemsLoading,
      keyword,
      data,
      totalItems
    },
    functions: {
      onPageChange: handleChangePage, 
      handleSearch: handleSearch, 
      handleClearSearch: handleClearSearch, 
      handleFilter: onFilter,
      onFirstPage: handleFirstPage
    }
  }

  const stickyTableHeadProps = {
    values: {
      order, 
      orderBy, 
      rowCount: data.length, 
      tableHeads: stickyHeaders,
      isSmallTable: tableWidth <= 1098 ? true : false
    },
    functions: {
      onRequestSort: createSortHandler
    }
  }

  const stickyTableRowProps = {
    values: { 
      rowsPerPage, 
      page, 
      columns: stickyHeaders, 
      tableHeads: stickyColumnIds,
      data: stickyData,
      module,
      rowHoverId,
      isSmallTable: tableWidth <= 1098 ? true : false,
      selectedRow
    },
    functions: {
      onPageChange: handleChangePage, 
      handleRowClick,
      onHover
    },
    tableHeadProps: stickyTableHeadProps
  }

  const tableHeadProps = {
    values: {
      order, 
      orderBy, 
      rowCount: data.length, 
      tableHeads: nonStickyHeaders,
      isSmallTable: tableWidth <= 1098 ? true : false
    },
    functions: {
      onRequestSort: createSortHandler
    }
  }

  const tableRowProps = {
    values: { 
      rowsPerPage, 
      page, 
      columns: nonStickyHeaders, 
      tableHeads: nonStickyColumnIds,
      data: nonStickyData,
      module,
      rowHoverId,
      isSmallTable: tableWidth <= 1098 ? true : false,
      selectedRow
    },
    functions: {
      onPageChange: handleChangePage, 
      handleRowClick,
      onHover
    },
    tableHeadProps: tableHeadProps
  }

  const emptyTableHeadProps = {
    values: {
      order, 
      orderBy, 
      rowCount: data.length, 
      tableHeads,
      isSmallTable: tableWidth <= 1098 ? true : false
    },
    functions: {
      onRequestSort: createSortHandler
    }
  }
  
  const stickyRef    = useRef();
  const nonStickyRef = useRef();

  const onScroll = (scroll) => {
    stickyRef.current.scrollTop    = scroll.target.scrollTop;
    nonStickyRef.current.scrollTop = scroll.target.scrollTop;
    stickyRef.current.scrollLeft   = 0;
  };

  return (
    <Box className={classes.tableBox} ref={tableRef}>
      <EnhancedTableToolbar {...tableToolbarProps} />
      <Paper className={clsx(data.length > 0 && classes.paper, classes.cutBar)} elevation={2}>
        <TableContainer className={classes.toolView}>
          {
            isLoading ?
              <Table data-testid={`main-table`} className={classes.stickyMainTable} aria-labelledby="tableTitle" size={'medium'}>
                <EnhancedTableHead {...emptyTableHeadProps}/>
                <SkeletonRow columns={columns} rowsPerPage={12} />
              </Table>
            :
              data.length > 0 ?
                <>
                  <div
                    onScroll={onScroll}
                    ref={stickyRef}
                    className={clsx(classes.stickyColumns, tableWidth <= 1427 && classes.divider)}
                  >
                    <Table data-testid={`main-table`} className={classes.stickyMainTable} aria-labelledby="tableTitle" size={'medium'}>
                      <EnhancedTableContent {...stickyTableRowProps} />
                    </Table>
                  </div>
                  <div
                    onScroll={onScroll}
                    ref={nonStickyRef}
                    className={classes.nonStickyColumns}
                  >
                    <Table data-testid={`main-table`} className={classes.mainTable} aria-labelledby="tableTitle" size={'medium'}>
                      <EnhancedTableContent {...tableRowProps} />
                    </Table>
                  </div>
                </>
              :
                <EnhancedEmptyTable module={module}/>
          }
        </TableContainer>
      </Paper>
    </Box>
  );
}
EnhancedTable.propTypes = EnhancedTableProptypes;

export default EnhancedTable;
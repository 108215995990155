import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  drawerContentContainer: {
    width       : theme.spacing(46),
    paddingLeft : theme.spacing(2),
    paddingTop  : theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  closeButtonContainer:{
    height        : theme.spacing(6),
    display       : 'flex',
    justifyContent: 'flex-end',
    alignItems    : 'center',
    padding       : theme.spacing(1)
  },
  drawerCloseButton: {
    color : colors.sesamsec_sys_Light_On_Surface_Variant,
    cursor: 'pointer'
  },
  drawerTitle: {
    fontWeight  : 'bold',
    fontSize    : theme.spacing(2.75),
    paddingRight: theme.spacing(2),
    lineHeight  : '26.4px'
  },
  eventTime: {
    fontSize: theme.spacing(1.75)
  },
  chipSkeletonContainer: {
    marginTop   : theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  chipContainer: {
    display        : 'inline-flex',
    alignItems     : 'center',
    padding        : '4px 8px',
    backgroundColor: '#EAEBEB',
    borderRadius   : theme.spacing(1),
    marginTop      : theme.spacing(3),
    marginBottom   : theme.spacing(3)
  },
  chipText: {
    marginLeft: theme.spacing(0.5),
    fontSize  : theme.spacing(1.75)
  },
  helperContainer: {
    marginTop     : theme.spacing(3), 
    marginBottom  : theme.spacing(1), 
    textDecoration: 'underline', 
    cursor        : 'pointer'
  },
  card: {
    border      : `1px solid ${colors.sesamsec_sys_Light_Outline_Silent}`,
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  },
  cardTitle: {
    fontSize  : theme.spacing(2),
    fontWeight: 'bold',
    lineHeight: 0,
  },
  fieldContainer: {
    display      : 'flex',
    flexDirection: 'column',
  },
  fieldRow: {
    display     : 'flex',
    marginBottom: theme.spacing(2),
    alignItems  : 'start'
  },
  lastFieldRow: {
    display: 'flex',
  },
  fieldKey: {
    width   : theme.spacing(17.5),
    minWidth: theme.spacing(17.5),
    fontSize: theme.spacing(1.75),
    color   : colors.sesamsec_sys_Light_On_Surface_Variant
  },
  fieldValue: {
    maxWidth      : theme.spacing(24),
    display       : 'flex',
    flex          : '1',
    wordWrap      : 'break-word',
    color         : colors.sesamsec_sys_Light_On_Surface_Variant,
    alignItems    : 'center',
    justifyContent: 'space-between'
  },
  emphasizeFieldValue: {
    display   : 'flex',
    flex      : '1',
    wordWrap  : 'break-word',
    color     : colors.sesamsec_sys_Light_On_Surface,
    alignItems: 'center',
    margin:    '0px 0px',
    justifyContent: 'space-between'
  },
  presentEntity: {
    cursor        : 'pointer',
    textDecoration: 'underline',
  },
  valueContainer: {
    display   : 'flex',
    alignItems: 'center'
  },
  baseStatus: {
    width       : theme.spacing(1.5), 
    height      : theme.spacing(1.5),
    borderRadius: '50%',
    display     : 'inline-block',
    marginRight : theme.spacing(0.75)
  },
  onlineStatus: {
    backgroundColor: colors.sesamsec_sys_Light_Secondary, 
  },
  offlineStatus: {
    backgroundColor: colors.sesamsec_sys_Light_On_Surface_Variant, 
  },
  noteDivider: {
    borderBottom: `1px dashed ${colors.sesamsec_sys_Light_Outline_Variant}`,
  },
  noteContainer: {
    marginTop: theme.spacing(2)
  },
  noteText: {
    fontSize: theme.spacing(1.75),
    color   : colors.sesamsec_sys_Light_On_Surface_Variant
  },
  infoIcon: {
    color     : colors.sesamsec_sys_Light_On_Surface_Variant,
    fontSize  : theme.spacing(2.25),
    marginLeft: theme.spacing(0.5)
  },
  deleteEntityTooltip: {
    fontSize: theme.spacing(1.75)
  }
}))
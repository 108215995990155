import { Box, Grid, IconButton, Paper, Switch, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore, Wifi as WifiIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHIP_COLOR, CHIP_COMPONENT, CONTRACT_ALL, CONTROLLERS_MODULE, CREDENTIALS_MODULE, EVENTS_MODULE, EXPAND_ALL, EXPAND_COLLAPSE, READERS } from '../../utility/constants';
import Chip from '../chip';
import Empty from '../empty';
import LoadingChips from './chips-skeleton';
import useStyles from './styles';

const ChipDetail = (props) => {
  const {details, showDetails, elementId, info } = props;

  return (
    details && showDetails.some(value => value === info) ?
      <Box>
        <ChipDetails elementId={elementId} details={details} />
      </Box> 
    :
      <></>
  )
}

const ChipDetails = (props) => {
  const { details, elementId } = props;

  return (
    details.length > 0 ? 
      <>
        <Box>
          {
            details.map((detail, index) => (
              <Chip
                id={`${elementId}${index}ReaderDetail`}
                recordId={index}
                icon={<WifiIcon fontSize="small" color="primary" />}
                title={detail.name ? detail.name : ''}
                description={detail.description ? detail.description : ''}
                firmware={detail.firmware}
                color={CHIP_COLOR.PLAIN}
                fullWidth={true}
                isFor={CHIP_COMPONENT.READER}
              />
            ))
          }
        </Box>
      </>
    :
      <></>
  )
}

const EnhancedChips = (props) => {
  const classes = useStyles();
  const { data, header, icon, color, leftHeader, rightHeader, type, handleSwitchChange, handleShowDetails, showDetails, handleViewAll, expandAll, viewAll, elementId } = props;

  const { t } = useTranslation();

  const isControllerModule = type === CONTROLLERS_MODULE;

  const getElementRecordId = (id, index) => {
    if (type === READERS) {
      return index;
    } else if (type === EVENTS_MODULE) {
      return id.replace('{?projection}', '');
    } else {
      return id;
    }
  }

  return (
    data.length > 0 ?
      <Box>
        {
          !isControllerModule &&
            <Box className={classes.title}>
              <Typography className={'bold'} color="secondary">{header}</Typography>
              <IconButton 
                id={`${elementId}ViewAllButton`}
                size="small" 
                edge="end"
                className={clsx({
                  'hidden': !viewAll || isControllerModule
                })}
                onClick={handleViewAll}
              >
                <ExpandMore fontSize="small" className={clsx({ 'hidden' : expandAll && showDetails.length > 0 })} />
                <ExpandLess fontSize="small" className={clsx({ 'hidden' : !expandAll || showDetails.length === 0 })} />
              </IconButton> 
            </Box>
        }
        <Paper 
          id={elementId}
          elevation={3}
          className={
            clsx(
              classes.paper,
              {[classes.breather]: isControllerModule}
            )
          }
        >
          {
            isControllerModule &&
              <Typography className={classes.accessPointTitle}>{header}</Typography>
          }
          {
            leftHeader || rightHeader ?
              <Box className={clsx(classes.header)}>
                <Typography variant="caption" className={classes.headerTitle}>
                  {leftHeader}
                </Typography>
                <Typography variant="caption" className={`${classes.headerTitle} ${classes.visuallyHidden}`}>-</Typography>
                <Typography variant="caption" className={classes.headerTitle}>
                  {rightHeader}
                </Typography> 
              </Box>
            :
              <></>
          }
          {
            data.map((info, index) => {
              const { id, name, description, endContent, details } = info;
              
              const elementRecordId = getElementRecordId(id, index);

              return (
                <Grid container spacing={2} key={id + index}>
                  <Grid id={`${elementId}${elementRecordId}Chip`} item xs={12}>
                    <Chip
                      id={elementId}
                      recordId={elementRecordId}
                      color={color}
                      icon={icon}
                      title={name}
                      description={description}
                      fullWidth={true}
                      isFor={isControllerModule && CHIP_COMPONENT.ACCESS_POINT}
                      startComponent={() => {
                        if (isControllerModule) {
                          return (
                            <IconButton size="small" onClick={(e) => handleShowDetails(e, info)}>
                              <ExpandMore
                                fontSize="small" 
                                className={clsx({
                                  'hidden': showDetails.some(value => value === info)
                                })}
                              />
                              <ExpandLess 
                                fontSize="small" 
                                className={clsx({
                                  'hidden': !Boolean(showDetails.some(value => value === info))
                                })}
                              />
                            </IconButton>
                          )
                        } else {
                          return null;
                        }
                      }}
                      endComponent={() => {
                        if (type === CREDENTIALS_MODULE) {
                          return (
                            <Switch
                              id={`${elementId}${elementRecordId}ActiveSwitch`}
                              disabled
                              className={classes.switch}
                              defaultChecked={endContent}
                              onChange={(e) => handleSwitchChange(e, info)}
                              size="small"
                            />
                          )
                        } else if (type === EVENTS_MODULE) {
                          return (
                            <span id={`${elementId}${elementRecordId}DateCreated`}>
                              {endContent}
                            </span>
                          )
                        } else {
                          return null
                        }
                      }}
                    />
                    <ChipDetail
                      details={details}
                      showDetails={showDetails}
                      elementId={elementId}
                      info={info}
                    />
                  </Grid>
                </Grid>
              )
            })
          }
          <Typography
            variant="caption"
            onClick={handleViewAll}
            className={
              clsx(
                classes.expandButton, 
                { 
                  'hidden' : data.length === 0 || !viewAll
                })
            }
          >
            {
              expandAll ?
                t(EXPAND_COLLAPSE[CONTRACT_ALL])
              :
                t(EXPAND_COLLAPSE[EXPAND_ALL])
            }
          </Typography> 
        </Paper>
      </Box>
    :
      <>
        <Typography className={isControllerModule ? 'hidden' : 'bold'} color="secondary">{header}</Typography>
        <Paper className={classes.paper} elevation={3}>
          <Typography className={isControllerModule ? classes.accessPointTitle : 'hidden'}>{header}</Typography>
          <Empty variant={'caption'} />
        </Paper>
      </>
  )
}

const Chips = (props) => {
  const { data, header, icon, color, leftHeader, rightHeader, type, onChange, isLoading, viewAll = false, id } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [showDetails, setShowDetails] = useState([])
  const [expandAll, setExpandAll] = useState(false)

  const handleSwitchChange = (e, info) => {
    setIsChecked(e.target.checked);
    onChange(isChecked, info)
  }

  const handleShowDetails = (e, info) => {
    let newShowDetails = [...new Set([...showDetails, info])];

    if (showDetails.some(value => value === info)) {
      newShowDetails = showDetails.filter(value => value !== info);
    }

    setShowDetails(newShowDetails);

    const isExpandedAll = newShowDetails.length === data.length;
    setExpandAll(isExpandedAll);
}

  const handleViewAll = () => {
    setExpandAll(!expandAll)
    if (expandAll) setShowDetails([])
    else setShowDetails(data)
  }

  return (
    isLoading ? 
      <LoadingChips
        leftHeader={leftHeader}
        rightHeader={rightHeader}
        type={type}
      />
    :
      <EnhancedChips
        elementId={id}
        color={color}
        data={data}
        expandAll={expandAll}
        handleShowDetails={handleShowDetails}
        handleSwitchChange={handleSwitchChange}
        handleViewAll={handleViewAll}
        header={header}
        icon={icon}
        leftHeader={leftHeader}
        rightHeader={rightHeader}
        showDetails={showDetails}
        type={type}
        viewAll={viewAll}
      />
  );
}

export default Chips;
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  switch: {
    width  : 43,
    height : 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding        : 0,
      margin         : 1.29,
      '&.Mui-checked': {
        color     : theme.palette.common.white,
        marginLeft: theme.spacing(-0.20),
        transform : 'translateX(29px) translateY(-0.4px)' ,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          border         : 0,
          opacity        : 1,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width    : 15,
      height   : 15,
    },
    '& .MuiSwitch-track': {
      borderRadius   : 25 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity        : 1,
      transition     : theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  header: {
    display       : 'flex',
    justifyContent: 'space-between',
    paddingBottom : theme.spacing(0.5)
  },
  headerTitle: {
    color       : colors.SECONDARY,
    fontWeight  : 'bold',
    lineHeight  : 1,
    paddingLeft : theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  loading: {
    borderRadius: theme.spacing(0.4),
    height      : theme.spacing(3.8),
    padding     : theme.spacing(1),
  },
  loadingHeader: {
    height     : theme.spacing(1.5),
    marginLeft : theme.spacing(1),
    marginRight: theme.spacing(1),
    width      : 50,
  },
  title: {
    display                : 'flex',
    flexDirection          : 'row',
    justifyContent         : 'space-between',
    paddingRight           : theme.spacing(1),
    '& .MuiIconButton-root': {
      padding: 0,
    }
  },
  visuallyHidden: {
    visibility: 'hidden'
  },
  expandButton: {
    display       : 'flex',
    justifyContent: 'center',
    paddingTop    : theme.spacing(2.5),
    paddingBottom : theme.spacing(0.5),
    color         : theme.palette.primary.main,
    cursor        : 'pointer',
  },
  accessPointChip: {
    boxShadow    : '0px 1px 0px 0px rgba(0, 0, 0, 0.15)',
    marginBottom : theme.spacing(1)
  },
  breather: {
    paddingBottom : theme.spacing(2),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(3),
    paddingTop    : theme.spacing(4)
  },
  accessPointTitle: {
    fontSize     : '22px',
    fontWeight   : 700,
    lineHeight   :'22px',
    paddingBottom: theme.spacing(2.5)
  },
}));
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  chip: {
    borderRadius  : theme.spacing(0.4),
    height        : 'inherit',
    justifyContent: 'start',
    maxWidth      : 185,
    paddingBottom : theme.spacing(0.4),
    paddingLeft   : theme.spacing(1),
    paddingRight  : theme.spacing(1),
    paddingTop    : theme.spacing(0.4),
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    
    '& .MuiTypography-root': {
      lineHeight: 1,
    },
    '& .MuiChip-label': {
      paddingLeft : 0,
      paddingRight: 0,
      width       : '100%',
    },
    '& .MuiChip-deleteIcon': {
      marginLeft  : theme.spacing(0.5),
      marginRight : 0
    },
    '& .MuiChip-icon': {
      marginLeft  : 0,
      marginRight : theme.spacing(0.5),
    },
    '& .MuiChip-labelSmall': {
      paddingLeft: 0,
    },
  },
  UnsyncChip: {
    paddingBottom : theme.spacing(-0.8),
    paddingLeft   : theme.spacing(-2),
    paddingRight  : theme.spacing(-2),
    paddingTop    : theme.spacing(-0.8),
  },
  fullWidth: {
    maxWidth: '100%',
    width   : '100%'
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    color          : theme.palette.primary.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  online: {
    backgroundColor: theme.palette.success.main,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.main,
    }
  },
  offline: {
    backgroundColor: theme.palette.text.secondary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.text.secondary,
    }
  },
  neutral: {
    backgroundColor: theme.palette.secondary.light,
    color          : theme.palette.text.primary,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  label: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    minHeight     : theme.spacing(3),
    width         : '100%'
  },
  minLabel: {
    '& .MuiBox-root': {
      width: 'inherit'
    }
  },
  outline: {
    backgroundColor : 'transparent',
    color           : colors.SECONDARY,
    fontWeight      : 1,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    }
  },
  plain: {
    backgroundColor: colors.WHITE,
    color          : colors.CHIP_PLAIN,
    '&:hover, &:focus': {
      backgroundColor: colors.WHITE,
    },
    padding        : theme.spacing(1.25)
  },
  readerChip: {
    alignItems    : 'flex-start',
    borderRadius  : 0,
    boxShadow     : '0px 1px 0px 0px rgba(0, 0, 0, 0.15)',
    paddingBottom : theme.spacing(2),
    paddingTop    : theme.spacing(1),
    wordWrap      : 'break-word',
    '& .MuiChip-icon': {
      height      : theme.spacing(3),
      marginLeft  : theme.spacing(1),
      marginRight : theme.spacing(2),
      width       : theme.spacing(3)
    },
    '& .MuiTypography-root': {
      lineHeight : 1.4,
      whiteSpace : 'break-spaces',
    },
  },
  accessPointChip: {
    borderRadius : 0,
    boxShadow    : '0px 1px 0px 0px rgba(0, 0, 0, 0.15)',
    color        : colors.CHIP_PLAIN,
    marginBottom : theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft  : 0,
    '& .MuiChip-icon': {
      color    : colors.CHIP_ICON_PLAIN,
      fontSize : theme.spacing(3)
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.75)
    }
  },
  labelContainer: {
    display: 'flex'
  },
  title: {
    color   : colors.DARK_GRAY,
    fontSize: '14px'
  },
  readerTitle: {
    color     : colors.DARK_GRAY,
    fontSize  : '14px',
    fontWeight: 700
  },
  description: {
    color       : colors.DARK_GRAY_TEXT,
    fontSize    : '14px'
  },
  accessPointLabel: {
    boxShadow    : '0px 1px 0px 0px rgba(0, 0, 0, 0.15)',
    marginBottom : theme.spacing(1)
  },
}))
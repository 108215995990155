import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  stickyTableCell: {
    backgroundColor: 'white',
    minWidth       : '0 !important',
    position       : 'sticky',
    zIndex         : 1
  },
  smallerTableCell: {
    position: 'sticky',
  },
  selectedStickyCell: {
    backgroundColor: colors.COMPONENT_STATE_HOVER,
  },
  paper: {
    marginBottom: theme.spacing(5),
    width       : '100%',
  },
  cutBar: {
    height   : '100%',
    position : 'relative',
  },
  root: {
    paddingLeft : theme.spacing(0.4),
    paddingRight: theme.spacing(1),
  },
  toolView: {
    display       : 'flex',
    height        : '100%',
    margin        : '0 auto',
    overflow      : 'hidden',
    position      : 'relative'
  },
  toolbar: {
    paddingLeft  : theme.spacing(0.625),
    paddingRight : theme.spacing(0)
  },
  toolBarItemsContainer: {
    alignItems: 'center'
  }, 
  paginationContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'right',
    position: 'relative',
    '& .MuiSkeleton-root' : {
      position: 'absolute',
      right: theme.spacing(15)
    }
  },
  paginationHide: {
    '& .MuiTablePagination-caption': {
      display: 'none'
    }
  },
  filterButton: {
    alignItems   : 'center',
    background   : 'none',
    border       : 'none',
    borderRadius : theme.spacing(1),
    boxShadow    : 'none',
    color        : colors.PRIMARY,
    display      : 'flex',
    height       : theme.spacing(5.625),
    marginLeft   : theme.spacing(1),
    marginTop    : theme.spacing(-1),
    padding      : theme.spacing(1),
    textTransform: 'none',
    width        : 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      boxShadow      : 'none',
    },
  },
  searchField: {
    display : 'block',
    float   : 'left',
    left    : theme.spacing(-0.5),
    margin  : 0,
    position: 'relative',
    top     : theme.spacing(-0.5),
    '& .MuiInputBase-root': {
      height: theme.spacing(5.625)
    }
  },
  activeIcon: {
    color        : colors.DARK_GRAY,
    flexWrap     : 'nowrap',
    fontSize     : theme.spacing(2),
    fontWeight   : '800',
    marginBottom : theme.spacing(-.2),
    marginRight  : theme.spacing(.5),
  },
  inactiveIcon: {
    color        : theme.palette.secondary.main,
    flexWrap     : 'nowrap',
    fontSize     : theme.spacing(2),
    fontWeight   : '800',
    marginBottom : theme.spacing(-.2),
    marginRight  : theme.spacing(.5),
    opacity      : '16%'
  },
  columnHeader: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'contents',
    justifyContent: 'center',
  },
  columnItem: {
    color     : colors.LIGHT_GRAY_TEXT,
    flexWrap  : 'nowrap',
    fontSize  : theme.spacing(1.75),
    fontWeight: '400'
  },
  baseCell: {
    maxWidth     : '3vw',
    minWidth     : theme.spacing(25),
    overflow     : 'hidden',
    padding      : 0,
    paddingLeft  : theme.spacing(2),
    paddingRight : theme.spacing(2),
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap', 
  },
  dataRow: {
    cursor: 'pointer',
    height: theme.spacing(7)
  },
  dataRowHover: {
    backgroundColor : colors.COMPONENT_STATE_HOVER,
    cursor          : 'pointer',
    height          : theme.spacing(7),
    '& $stickyTableCell': {
      backgroundColor: colors.COMPONENT_STATE_HOVER, 
    }
  },
  selectedRow: {
    backgroundColor: colors.COMPONENT_STATE_HOVER,
  },
  headerStyle: {
    backgroundColor: colors.WHITE,
    height         : theme.spacing(7),
    padding        : theme.spacing(0),
    paddingLeft    : theme.spacing(2),
    position       : 'sticky',
    textAlign      : 'left',
    top            : 0,
    zIndex         : 3,
  },
  smallerTableHead: {
    backgroundColor: colors.WHITE,
    height         : theme.spacing(7),
    padding        : theme.spacing(0),
    paddingLeft    : theme.spacing(2),
    position       : 'sticky',
    textAlign      : 'left',
    top            : 0,
    zIndex         : 3,
  },
  icon: {
    height: `${theme.spacing(3)} !important`,
    width : `${theme.spacing(3)} !important`
  },
  visuallyHidden: {
    border  : 0,
    clip    : 'rect(0 0 0 0)',
    height  : 1,
    margin  : -1,
    overflow: 'hidden',
    padding : 0,
    position: 'absolute',
    top     : 20,
    width   : 1,
  },
  mainTable: {
    borderCollapse: 'unset'
  },
  stickyMainTable: {
    borderCollapse: 'unset',
    tableLayout   : 'auto'
  },
  tableBox: {
    width: '100%',
    height: '82%',
    '@media (max-height: 720px)' : {
        height: '75%'
    },
    '@media (max-height: 540px)' : {
        height: '70%'
    },
    '@media (max-height: 460px)' : {
        height: '60%'
    }
  },
  stickyColumns: {
    height         : '100%',
    minWidth       : theme.spacing(37.5),
    overflow       : 'auto',
    paddingBottom  : theme.spacing(2),
    scrollbarWidth : 'none',
    width          : '30%',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  nonStickyColumns: {
    height  : '100%',
    flex    : 1,
    overflow: 'auto'
  },
  divider: {
    borderRight : '2px solid ' + colors.LIGHT_GRAY
  },
  timeColumn: {
    width   : theme.spacing(11),
    maxWidth: 'fit-content'
  }
}));
import React from 'react';
import { Paper, Box, Typography, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import useStyles from './styles';
import { CONTROLLERS_MODULE } from '../../../utility/constants';

const LoadingChips = (props) => {
  const classes = useStyles();
  const {leftHeader, rightHeader, type} = props;

  return (
    <Box>
      <Paper className={type === CONTROLLERS_MODULE ? classes.paper : classes.paperHeader} elevation={3}>
        <Box className={clsx(classes.header)}>
          {
            leftHeader || rightHeader ? 
              <>
                <Skeleton variant="text" className={classes.loadingHeader}/>
                <Skeleton variant="text" className={classes.loadingHeader}/> 
              </>
            :
              <Typography variant="caption" className={`${classes.headerTitle} ${classes.visuallyHidden}`}>-</Typography>
          }
        </Box>
        {
          [...Array(2)].map((i, index) => {

            return (
              <Grid container spacing={2} key={i + '-skeleton-id-' + index}>
                <Grid item xs={12}>
                  <Skeleton variant="rect" className={classes.loading}/>
                </Grid>
              </Grid>
            )
          })
        }
      </Paper>
    </Box>
  )
}

export default LoadingChips;
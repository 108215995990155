import { GET, PATCH } from "../utility/constants";
import api from "./api";
import { request } from "./requests";

export const getEventList = async(params, cancelToken = null) => {
  const response = await request({
    url: api.EVENTS,
    method: GET,
    params: params,
    cancelToken: cancelToken
  });

  return response.data;
}

export const getEventListCount = async(params, cancelToken = null) => {
  const response = await request({
    url: `${api.EVENTS}/count`,
    method: GET,
    params: params,
    cancelToken: cancelToken
  });

  return response.data;
}

export const getEventsMonthlyReport = async (params) => {
  return await request({
    url    : api.EVENT_FILTER_EVENT_MONTHLY_REPORT,
    method : GET,
    params : params
  })
}

export const getEventsYearlyReport = async (params) => {
  return await request({
    url    : api.EVENT_FILTER_EVENT_YEARLY_REPORT,
    method : GET,
    params : params
  })
}

export const getEventConfigurations = async () => {
  const response = await request({
    url    : api.EVENT_CONFIGURATIONS,
    method : GET
  });

  return response;
}

export const updateEventConfigurations = async (isEventExpirationEnabled, datepart, expiration) => {
  await request({
    url    : `${api.EVENT_CONFIGURATIONS}/1`,
    method : PATCH,
    data   : {
      isEventExpirationEnabled : isEventExpirationEnabled,
      datepart                 : datepart,
      expiration               : expiration
    }
  });
}

export const getEventControllers = async (params) => {
  return await request({
    url : api.EVENT_GET_CONTROLLERS,
    method: GET,
    params: params
  })
}
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  width1: {
    width: theme.spacing(16.75)
  },
  width2: {
    width: theme.spacing(38)
  },
  height1: {
    height: theme.spacing(2.5)
  },
  height2: {
    height: theme.spacing(4)
  },
  height3: {
    height: theme.spacing(5)
  }
  
}))
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    height        : '100%',
    maxWidth      : '100%',
    paddingTop    : theme.spacing(2),
    paddingBottom : theme.spacing(1)
  },
  root: {
    width : '100%',
  },
  paper: {
    width         : '100%',
    marginBottom  : theme.spacing(2),
    marginTop     : theme.spacing(4),
  },
  table: {
    minWidth  : 750,
  },
  visuallyHidden: {
    border    : 0,
    clip      : 'rect(0 0 0 0)',
    height    : 1,
    margin    : -1,
    overflow  : 'hidden',
    padding   : 0,
    position  : 'absolute',
    top       : 20,
    width     : 1,
  },
}));
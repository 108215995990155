import React from 'react';
import { Container, Grid, TableBody, TableCell, TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';

const EventChartSkeleton = () => {
  const classes = useStyles();
  return (
    <Container className={classes.chartContainer}>
      <Skeleton height={400}/>
    </Container>
  )
}

const TableEventsSkeleton = (props) => {
  const { rowsPerPage } = props; 
  const classes = useStyles();
  
  return (
    <TableBody className={classes.chartContainer}>
      {
        [...new Array(rowsPerPage)].map((_, index) => {
          return (
            <TableRow className={classes.contents} key={index}>
              <TableCell component="th" scope="row">
                 <Skeleton height={22} width={200}/>
              </TableCell>
              <TableCell scope="row">
                 <Skeleton height={22}  width={150}/>
              </TableCell>
            </TableRow>
          )
        })
      }
    </TableBody>
  )
}

const ChipsSkeleton = () => {
  const classes = useStyles();
  return (
    <Container className={classes.chipsContainer}>
      <Skeleton className={classes.chipSkeleton}/>
    </Container>
  );
}

const ChipDetailsSkeleton = () => {
  const classes = useStyles();
  return (
    <Container className={classes.chipDetailsContainer}>
      <Skeleton className={classes.chipDetailSkeleton}/>
    </Container>
  )
}

const ChipListSkeleton = (props) => {
  const { isCompactView } = props;
  const classes = useStyles();
  const height = isCompactView ? 615 : 225;
  return (
    <Container className={classes.chipListContainer}>
      <Skeleton height={height}/>
    </Container>
  )
}

const EventsContainerSkeleton = (props) => {
  const classes = useStyles();

  return(
    <Container className={classes.chartContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton height={483} className={classes.eventSkeleton}/>
        </Grid>
      </Grid>
    </Container>
  );
}

export  { ChipsSkeleton, ChipDetailsSkeleton, ChipListSkeleton, EventsContainerSkeleton, TableEventsSkeleton, EventChartSkeleton };
import { ImportExportOutlined, InputOutlined, MeetingRoom, MoreHoriz, NotificationsActiveOutlined, Settings, SettingsRemote, SurroundSound, SyncAltOutlined, Update, WifiOutlined } from "@material-ui/icons";
import { ACCESS_CHECK, ACCESS_DENIED, ACCESS_DENIED_CONTROL_POINTS, ACCESS_DENIED_DATE_TIME, ACCESS_DENIED_HOLIDAY, ACCESS_DENIED_INACTIVE_BADGE, ACCESS_DENIED_INVALID, ACCESS_DENIED_INVALID_BADGE, ACCESS_DENIED_PERSON, ACCESS_DENIED_PROFILE, ACCESS_DENIED_ROLE, ACCESS_GRANTED, ACCESS_GRANTED_PINCODE, APPLICATION_UPDATE_FAILED, APPLICATION_UPDATE_STARTED, APPLICATION_UPDATE_SUCCEEDED, CERTIFICATE_IMPORT, CERTIFICATE_IMPORT_ERROR, CERTIFICATE_IMPORT_SUCCESS, CONFIGURATION_FAILED, CONFIGURATION_SUCCESS, DB_EXPORT, DB_IMPORT, DEVICE_REQUEST, DOOR, DOOR_CLOSED, DOOR_MODE_CLOSED, DOOR_MODE_EMA, DOOR_MODE_OPEN, DOOR_OPEN, DOOR_OPEN_LONG_ALARM, DOOR_OPEN_NORMAL, DOOR_OPEN_PRE_ALARM, DOOR_OPEN_REMOTE, DOWNLOAD_ZIP_FILE_FAILED, DOWNLOAD_ZIP_FILE_STARTED, DOWNLOAD_ZIP_FILE_SUCCEEDED, EAP_CERT_INFO, EAP_COMPLETED, EAP_METHOD, EAP_STATUS, EMA, EMA_ACCESS_GRANTED, EMA_DISARM_SYSTEM, EMA_FAILED_NOT_READY_SYSTEM, EMA_FAILED_SYSTEM, EMA_SYSTEM, EMA_SYSTEM_ARMED, EMA_SYSTEM_DISARMED, EMA_SYSTEM_DISARMED_FAILED, EMA_SYSTEM_SWITCH, ETHERNET, FORCE_OPEN, FORCE_OPEN_ARMED, IMPORT_EXPORT, OS_UPDATE_FAILED, OS_UPDATE_STARTED, OS_UPDATE_SUCCEEDED, PACKAGE_UPDATE_FAILED, PACKAGE_UPDATE_STARTED, PACKAGE_UPDATE_SUCCEEDED, READER, READER_BADGE, READER_BATTERY, READER_COUPLING, READER_KNOB_IN, READER_KNOB_OUT, READER_OFFLINE, READER_ONLINE, READER_SABOTAGE, READER_TEACH, READER_UPDATE_STARTED, REMOTE_CONTROL, SDDL, SDDL_ADD, SDDL_CHG, SDDL_DEL, SDDL_END, SDDL_ERR, SDDL_START, SERVICE, SERVICE_DATE_TIME, SERVICE_EXIT, SERVICE_LOGIN, SERVICE_SHUTDOWN, SERVICE_STARTED, START_DOOR_OPEN, STOP_DOOR_OPEN, TWN4_UPDATE_FAILED, TWN4_UPDATE_PASSED, TWN4_UPDATE_STARTED, TWN4_UPDATE_SUCCEEDED, UNZIP_PACKAGE_FAILED, UNZIP_PACKAGE_STARTED, UNZIP_PACKAGE_SUCCEEDED, UPDATE_DAEMON_TYPE } from "./constants";
import images from "./images";
import { ShieldOutlined } from "@mui/icons-material";

export const getEventTypeIcon = (event) => {
  switch (event) {
    case DOOR:
      return <MeetingRoom fontSize="small"/>
    case READER:
      return <SurroundSound fontSize="small"/>
    case ACCESS_CHECK:
      return <img src={images.ACCESS_CHECK_EVENT} alt="access-check-icon" />
    case EMA:
      return <NotificationsActiveOutlined fontSize="small"/>
    case SERVICE:
      return <Settings fontSize="small"/>
    case REMOTE_CONTROL:
      return <SettingsRemote fontSize="small"/>
    case IMPORT_EXPORT:
      return <ImportExportOutlined fontSize="small"/>
    case SDDL:
      return <InputOutlined fontSize="small"/>
    case UPDATE_DAEMON_TYPE:
      return <Update fontSize="small"/>
    case DEVICE_REQUEST:
      return <SyncAltOutlined fontSize="small"/>
    case ETHERNET:
      return <WifiOutlined fontSize="small"/>
    case CERTIFICATE_IMPORT:
      return <ShieldOutlined fontSize="small"/>
    default:
      return <MoreHoriz fontSize="small"/>;
  }
}

export const getSubTypeDescription = (type, subType) => {

  const descriptions = {
    Service: [
      {subType: SERVICE_STARTED, description: 'events-page-side-panel.systemStartedDescription'},
      {subType: SERVICE_LOGIN, description: 'events-page-side-panel.applicationLoginDescription'},
      {subType: SERVICE_SHUTDOWN, description: 'events-page-side-panel.shutdownDescription'},
      {subType: SERVICE_DATE_TIME, description: 'events-page-side-panel.dateTimeChangeDescription'},
      {subType: SERVICE_EXIT, description: 'events-page-side-panel.requesttoExitDescription'}
    ],
    EMA: [
      {subType: EMA_SYSTEM, description: 'events-page-side-panel.trytoarmSystemDescription'},
      {subType: EMA_SYSTEM_ARMED, description: 'events-page-side-panel.systemarmedDescription'},
      {subType: EMA_FAILED_SYSTEM, description: 'events-page-side-panel.failedtoarmSystemDescription'},
      {subType: EMA_FAILED_NOT_READY_SYSTEM, description: 'events-page-side-panel.failedtoarmSystemNotReadyDescription'},
      {subType: EMA_DISARM_SYSTEM, description: 'events-page-side-panel.trytodisarmSystemDescription'},
      {subType: EMA_SYSTEM_SWITCH, description: 'events-page-side-panel.trytodisarmSystemalreadyswitchedDescription'},
      {subType: EMA_SYSTEM_DISARMED, description: 'events-page-side-panel.systemdisarmedDescription'},
      {subType: EMA_SYSTEM_DISARMED_FAILED, description: 'events-page-side-panel.failedtodisarmSystemDescription'},
      {subType: EMA_ACCESS_GRANTED, description: 'events-page-side-panel.emaAccessGrantedDescription'}
    ],
    Reader: [
      {subType: READER_ONLINE, description: 'events-page-side-panel.readerOnlineDescription'},
      {subType: READER_OFFLINE, description: 'events-page-side-panel.readerOfflineDescription'},
      {subType: READER_BADGE, description: 'events-page-side-panel.readerBadgeDescription'},
      {subType: READER_SABOTAGE, description: 'vents-page-side-panel.readerSabotageDescription'},
      {subType: READER_BATTERY, description: 'events-page-side-panel.readerBatteryWarningDescription'},
      {subType: READER_COUPLING, description: ''},
      {subType: READER_KNOB_IN, description: ''},
      {subType: READER_KNOB_OUT, description: ''},
      {subType: READER_TEACH, description: ''}
    ],
    Door: [
      {subType: FORCE_OPEN, description: 'events-page-side-panel.forcedOpenDescription'},
      {subType: FORCE_OPEN_ARMED, description: 'eevents-page-side-panel.forcedOpenwhilearmedDescription'},
      {subType: DOOR_OPEN, description: 'events-page-side-panel.doorOpenDescription'},
      {subType: DOOR_CLOSED, description: 'events-page-side-panel.doorClosedDescription'},
      {subType: DOOR_OPEN_PRE_ALARM, description: 'events-page-side-panel.doorOpenTooLongPrealarmDescription'},
      {subType: DOOR_OPEN_LONG_ALARM, description: 'events-page-side-panel.doorOpenTooLongAlarmDescription'},
      {subType: START_DOOR_OPEN, description: 'events-page-side-panel.startDoorOpenProfileDescription'},
      {subType: STOP_DOOR_OPEN, description: 'events-page-side-panel.stopDoorOpenProfileDescription'},
      {subType: DOOR_OPEN_REMOTE, description: 'events-page-side-panel.doorOpenRemoteDescription'},
      {subType: DOOR_OPEN_NORMAL, description: 'events-page-side-panel.doorModeNormalDescription'},
      {subType: DOOR_MODE_OPEN, description: 'events-page-side-panel.doorModeOpenDescription'},
      {subType: DOOR_MODE_CLOSED, description: 'events-page-side-panel.doorModeClosedDescription'},
      {subType: DOOR_MODE_EMA, description: 'events-page-side-panel.doorModeEMAAlarmActiveDescription'}
    ],
    Access_Check: [
      {subType: ACCESS_DENIED, description: 'events-page-side-panel.accessDeniedDescription'},
      {subType: ACCESS_GRANTED, description: 'events-page-side-panel.accessGrantedDescription'},
      {subType: ACCESS_GRANTED_PINCODE, description: 'events-page-side-panel.accessGrantedPincodeDescription'},
      {subType: ACCESS_DENIED_INVALID, description: 'events-page-side-panel.accessDeniedInvalidPersonDescription'},
      {subType: ACCESS_DENIED_INVALID_BADGE, description: 'events-page-side-panel.accessDeniedInvalidBadgeDescription'},
      {subType: ACCESS_DENIED_INACTIVE_BADGE, description: 'events-page-side-panel.accessDeniedInactiveBadgeDescription'},
      {subType: ACCESS_DENIED_DATE_TIME, description: 'events-page-side-panel.accessDeniedAccessGroupScheduleDoesNotMatchWithDatetimeDescription'},
      {subType: ACCESS_DENIED_CONTROL_POINTS, description: 'events-page-side-panel.accessDeniedControlpointDoesNotMatchToListDescription'},
      {subType: ACCESS_DENIED_PROFILE, description: 'events-page-side-panel.accessDeniedNoControlpointorReaderforProfileDescription'},
      {subType: ACCESS_DENIED_ROLE, description: 'events-page-side-panel.accessDeniednoAccessGroupforRoleDescription'},
      {subType: ACCESS_DENIED_PERSON, description: 'events-page-side-panel.accessDeniedNoRoleforPersonDescription'},
      {subType: ACCESS_DENIED_HOLIDAY, description: 'events-page-side-panel.accessDeniedHolidayDescription'}
    ],
    Remote_Control: [
      {subType: DOOR_OPEN_REMOTE, description: 'events-page-side-panel.doorOpenRemoteDescription'}
    ],
    Import_Export: [
      {subType: DB_IMPORT, description: 'events-page-side-panel.dBImportStartedDescription'},
      {subType: DB_EXPORT, description: 'events-page-side-panel.dBExportStartedDescription'}
    ],
    SDDL: [
      {subType: SDDL_START, description: ''},
      {subType: SDDL_END, description: ''},
      {subType: SDDL_ADD, description: ''},
      {subType: SDDL_DEL, description: ''},
      {subType: SDDL_CHG, description: ''},
      {subType: SDDL_ERR, description: ''}
    ],
    Update_Daemon: [
      {subType: PACKAGE_UPDATE_STARTED, description: 'events-page-side-panel.packageUpdateStartedDescription'},
      {subType: DOWNLOAD_ZIP_FILE_STARTED, description: 'events-page-side-panel.downloadZIPFileStartedDescription'},
      {subType: DOWNLOAD_ZIP_FILE_SUCCEEDED, description: 'events-page-side-panel.downloadZIPFileSucceededDescription'},
      {subType: DOWNLOAD_ZIP_FILE_FAILED, description: 'events-page-side-panel.downloadZIPFileFailedDescription'},
      {subType: UNZIP_PACKAGE_STARTED, description: 'events-page-side-panel.unzipPackageStartedDescription'},
      {subType: UNZIP_PACKAGE_SUCCEEDED, description: 'events-page-side-panel.unzipPackageSucceededDescription'},
      {subType: UNZIP_PACKAGE_FAILED, description: 'events-page-side-panel.unzipPackageFailedDescription'},
      {subType: APPLICATION_UPDATE_STARTED, description: 'events-page-side-panel.applicationUpdateStartedDescription'},
      {subType: APPLICATION_UPDATE_SUCCEEDED, description: 'events-page-side-panel.applicationUpdateSucceededDescription'},
      {subType: APPLICATION_UPDATE_FAILED, description: 'events-page-side-panel.applicationUpdateFailedDescription'},
      {subType: OS_UPDATE_STARTED, description: 'events-page-side-panel.oSUpdateStartedDescription'},
      {subType: OS_UPDATE_SUCCEEDED, description: 'events-page-side-panel.oSUpdateSucceededDescription'},
      {subType: OS_UPDATE_FAILED, description: 'events-page-side-panel.oSUpdateFailedDescription'},
      {subType: READER_UPDATE_STARTED, description: 'events-page-side-panel.readersUpdateStartedDescription'},
      {subType: TWN4_UPDATE_STARTED, description: 'events-page-side-panel.tWN4UpdateStartedDescription'},
      {subType: TWN4_UPDATE_PASSED, description: 'events-page-side-panel.tWN4UpdatePassedDescription'},
      {subType: TWN4_UPDATE_SUCCEEDED, description: 'events-page-side-panel.tWN4UpdateSucceededDescription'},
      {subType: TWN4_UPDATE_SUCCEEDED, description: 'events-page-side-panel.tWN4UpdateSucceededDescription'},
      {subType: TWN4_UPDATE_FAILED, description: 'events-page-side-panel.unzipPackageFailedDescription'},
      {subType: PACKAGE_UPDATE_SUCCEEDED, description: 'events-page-side-panel.packageUpdateSucceededDescription'},
      {subType: PACKAGE_UPDATE_FAILED, description: 'events-page-side-panel.packageUpdateFailedDescription'}
    ],
    Ethernet: [
      {subType: CONFIGURATION_FAILED, description: ''},
      {subType: CONFIGURATION_SUCCESS, description: ''},
      {subType: EAP_STATUS, description: ''},
      {subType: EAP_METHOD, description: ''},
      {subType: EAP_CERT_INFO, description: ''},
      {subType: EAP_COMPLETED, description: ''}
    ],
    Certificate_Import: [
      {subType: CERTIFICATE_IMPORT_SUCCESS, description: ''},
      {subType: CERTIFICATE_IMPORT_ERROR, description: ''},
    ]
  }

  if (!type) {
    return '';
  }

  const descriptionsInType = descriptions[type.replace(' ', '_')];
  if (descriptionsInType) {
    const result = descriptionsInType.find(item => item.subType.toLowerCase() === subType.toLowerCase());
    return result ? result.description : '';
  }

  return '';
}
import React from 'react';
import { Paper, Box, Typography, Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const LoadingState = () => {
  const classes = useStyles()
  const { t }   = useTranslation();

  return (
    <Box>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} className={classes.form}>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.type')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.subType')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.info1')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.info2')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.readerFirmware')}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="caption" className={'bold'} color="secondary">{t('event-page.dateCreated')}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.form}  >
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
          <Grid item xs className={classes.boxBackground}>
            <Skeleton height={20}/>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}


export default LoadingState;
import { Box, Popover, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const EnhancedPopOver = (props) => {
  const { data, popoverState } = props;
  const { title, description } = data;
  const { anchorEl, handleClose } = popoverState;

  const open = Boolean(anchorEl);

  const {t}     = useTranslation();
  const classes = useStyles();

  const ids = open ? 'simple-popover' : undefined;
  return (
    <Popover
      className={classes.popoverRoot}
      id={ids}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{className: classes.popOverPaper}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Typography className={classes.description}>
        {t(description)}
      </Typography>
    </Popover>
  );
}

export default EnhancedPopOver;